// Generated by Framer (af04cc1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/KGUdUUiCG";

const serializationHash = "framer-Fwtus"

const variantClassNames = {Jusct9NXQ: "framer-v-133s4lm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, text, width, ...props}) => { return {...props, qkyBhp4Er: text ?? props.qkyBhp4Er ?? "Text Content"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, qkyBhp4Er, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Jusct9NXQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-133s4lm", className, classNames)} data-border data-framer-name={"Badge"} layoutDependency={layoutDependency} layoutId={"Jusct9NXQ"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-c6a8da26-b84c-470a-8765-9cd752ccb691, rgba(230, 230, 230, 0.1))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-0fb2a20a-b4d3-45b7-8fb2-3c30d2ac4f07, rgb(255, 255, 255))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-5j2dxw"} data-styles-preset={"KGUdUUiCG"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-4e04ecb2-4c8c-4232-853c-c5a9a14468f6, rgb(113, 93, 227)))"}}>What We Offer</motion.p></React.Fragment>} className={"framer-cuttc"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"oRgBK9hc_"} style={{"--extracted-r6o4lv": "var(--token-4e04ecb2-4c8c-4232-853c-c5a9a14468f6, rgb(113, 93, 227))", "--framer-paragraph-spacing": "0px"}} text={qkyBhp4Er} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Fwtus.framer-1lmhbb, .framer-Fwtus .framer-1lmhbb { display: block; }", ".framer-Fwtus.framer-133s4lm { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: flex-start; overflow: visible; padding: 6px 10px 6px 10px; position: relative; width: min-content; }", ".framer-Fwtus .framer-cuttc { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Fwtus.framer-133s4lm { gap: 0px; } .framer-Fwtus.framer-133s4lm > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-Fwtus.framer-133s4lm > :first-child { margin-left: 0px; } .framer-Fwtus.framer-133s4lm > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ".framer-Fwtus[data-border=\"true\"]::after, .framer-Fwtus [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 33
 * @framerIntrinsicWidth 105
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"qkyBhp4Er":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNuTZhnBTS: React.ComponentType<Props> = withCSS(Component, css, "framer-Fwtus") as typeof Component;
export default FramerNuTZhnBTS;

FramerNuTZhnBTS.displayName = "Badge Tag";

FramerNuTZhnBTS.defaultProps = {height: 33, width: 105};

addPropertyControls(FramerNuTZhnBTS, {qkyBhp4Er: {defaultValue: "Text Content", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramerNuTZhnBTS, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})